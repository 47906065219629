import React, { useEffect, useState, useRef, createRef } from "react";
import { graphql, Link } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "../components/slick/slick.css";
import "../components/slick/slick-theme.css";
import { GlobalHotKeys } from "react-hotkeys";
import { KnownFragmentNamesRule } from "graphql";
import { ImageOrientation } from "../components/utils/image-orientation";
import { findLastIndex } from "lodash";
const GlobalStyle = createGlobalStyle`
  body {
    /* background-color: black; */
    background-color: ${props => props.pageColor};
  }
`;

const ProjectPageCon = styled.div`
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  margin: 10px;
  position: relative;
  @media (max-width: 640px) {
    height: auto;
  }
`;
const NextImgButton = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2000;
  /* background-color: red; */
  position: absolute;
  /* background-color: blue; */
`;

const PagingItem = styled.div`
  display: block;
  float: left;
`;

const InfoCon = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
`;
const ProjectTitle = styled.h1`
  font-family: "Unica77LLWeb-Bold", helvetica;
  font-size: 18px;
  color: white;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -1px;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (max-width: 640px) {
    position: relative;
    padding-top: 20px;
  }
  @media (min-width: 1024px) and (orientation: portrait) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

const PagingButton = styled.button`
  background: none;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
  opacity: 0.6;
  line-height: 1;
`;

const CarouselCon = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  /* margin-left: 200px; */
  @media (max-width: 640px) {
    height: auto;
    position: relative;
    /* background-color: red; */
    margin-top: 10px;
    /* display: flex; */
    /* align-items: center; */
    /* height: 60vh; */
  }
`;

const NavCon = styled.nav`
  display: inline-block;
  position: absolute;
  vertical-align: top;
  z-index: 2000;
  @media (max-width: 640px) {
    position: relative;
  }
`;
const LiNavList = styled.li`
  font-family: "Unica77LLWeb-Bold", helvetica;
  font-size: 18px;
  line-height: 1;
  letter-spacing: -1px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) and (orientation: portrait) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

const ProjectMetaCon = styled.div`
  position: absolute;
  width: 30vw;
  bottom: 0;
  left: 0;
  z-index: 2500;
  /* background-color: red; */
  @media (max-width: 640px) {
    position: relative;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
    /* padding-top: 20px; */
  }
  @media (max-width: 900px) and (orientation: landscape) {
    width: 50vw;
  }
  @media (min-width: 768px) and (orientation: portrait) {
    width: 60vw;
  }
`;

const ProjectMetaP = styled.div`
  p,
  a {
    font-family: "Unica77LLWeb-Regular", helvetica;
    font-size: 12px;
    color: white;
    opacity: 0.6;
    line-height: 1;
    margin: 0;
    margin-top: 10px;
    @media (min-width: 1300px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) and (orientation: portrait) {
      font-size: 18px;
    }
  }
  a {
    color: blue;
    opacity: 1;
  }
`;

const Project = ({ data }) => {
  const elementsRef = useRef();
  const projectCarousel = data.prismicProject.data.project_carousel
    .filter(
      project_carousel => project_carousel.project_carousel_image.fluid !== null
    )
    .map((project_carousel, index) => (
      <img
        className={`project-carousel-img ${ImageOrientation(
          project_carousel.project_carousel_image
        )}`}
        key={`artist_image_${index}`}
        src={project_carousel.project_carousel_image.fluid.srcWebp}
        srcSet={project_carousel.project_carousel_image.fluid.srcSetWebp}
      />
    ));

  function imgClick() {
    SliderRefThree.current.slickNext();
  }

  const SliderRefThree = React.useRef(null);

  const Carousel = ({ children }) => {
    const PagingCon = styled.div`
      position: fixed;
      right: 10px !important;
      bottom: 10px !important;
      z-index: 2500;
      @media (max-width: 640px) {
        /* left: 0; */
        right: auto;
        bottom: -25px !important;
        /* left: -349px !important; */
        float: right;
        position: absolute;
        right: 1px !important;
      }
      a {
        font-family: "Unica77LLWeb-Bold", helvetica;
      }
    `;
    const PagingUl = styled.ul`
      @media (max-width: 640px) {
        float: left;
      }
    `;
    const settings = {
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: true,
      dots: true,
      arrows: false,
      // adaptiveHeight: true,
      customPaging: function(i) {
        return <a>{i + 1}</a>;
      },
      appendDots: dots => (
        <PagingCon>
          <PagingUl> {dots} </PagingUl>
        </PagingCon>
      ),
    };

    const keyMap = {
      MOVE_UP: "up",
      MOVE_LEFT: "left",
      MOVE_RIGHT: "right",
    };
    const handlers = {
      MOVE_UP: event => console.log(SliderRefThree.current),
      MOVE_LEFT: event => SliderRefThree.current.slickPrev(),
      MOVE_RIGHT: event => SliderRefThree.current.slickNext(),
    };

    return (
      <>
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
        <Slider
          key={`slider`}
          {...settings}
          ref={SliderRefThree}
          beforeChange={(oldIndex, newIndex) => {
            // updateCurrentCaption(newIndex);
            // updateCurrentYear(newIndex);
          }}
        >
          {children}
        </Slider>
      </>
    );
  };
  console.log(data.prismicProject.data.project_background_color);
  return (
    <>
      <GlobalStyle
        pageColor={data.prismicProject.data.project_background_color}
      />
      <Helmet>
        <title>Poppy Nash</title>
      </Helmet>
      <ProjectPageCon>
        <NextImgButton onClick={imgClick}></NextImgButton>
        <NavCon>
          <ul>
            <LiNavList>
              <Link to="/">Poppy Nash</Link>
            </LiNavList>
            <LiNavList>
              <Link to="/about">About</Link>
            </LiNavList>
          </ul>
        </NavCon>
        <ProjectTitle>
          {data.prismicProject.data.project_title.text}
        </ProjectTitle>
        <CarouselCon>
          <Carousel>{projectCarousel}</Carousel>
        </CarouselCon>

        <ProjectMetaCon>
          <ProjectMetaP>
            {/* {data.prismicProject.data.project_meta.text} */}
            <div
              className="about-text"
              dangerouslySetInnerHTML={{
                __html: data.prismicProject.data.project_meta.html,
              }}
            />
          </ProjectMetaP>
        </ProjectMetaCon>
      </ProjectPageCon>
    </>
  );
};

export default withPreview(Project);

export const query = graphql`
  query Artists($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      id
      data {
        project_title {
          html
          text
        }
        project_meta {
          html
          text
        }
        project_background_color
        project_carousel {
          project_carousel_image {
            fluid {
              srcSetWebp
              srcWebp
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;
